import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
const Features = () => {
  return (
    <div class="container-fluid bg-primary feature pt-5">
      <div class="container pt-5">
        <div class="row g-5">
          <div
            class="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn"
            data-wow-delay="0.3s"
          >
            <div class="btn btn-sm border rounded-pill text-white px-3 mb-3">
              Why Choose Us
            </div>
            <h1 class="text-white mb-4">
              We're Best in AI Industry with 10 Years of Experience
            </h1>
            <p class="text-light mb-4">
              Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et
              sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor
            </p>
            <div class="d-flex align-items-center text-white mb-3">
              <div class="btn-sm-square bg-white text-primary rounded-circle me-3">
                <AiOutlineCheck />
              </div>
              <span>Diam dolor diam ipsum et tempor sit</span>
            </div>
            <div class="d-flex align-items-center text-white mb-3">
              <div class="btn-sm-square bg-white text-primary rounded-circle me-3">
                <AiOutlineCheck />
              </div>
              <span>Diam dolor diam ipsum et tempor sit</span>
            </div>
            <div class="d-flex align-items-center text-white mb-3">
              <div class="btn-sm-square bg-white text-primary rounded-circle me-3">
                <AiOutlineCheck />
              </div>
              <span>Diam dolor diam ipsum et tempor sit</span>
            </div>
            <div class="row g-4 pt-3">
              <div class="col-sm-6">
                <div
                  class="d-flex rounded p-3"
                  style={{ background: "rgba(256, 256, 256, 0.1)" }}
                >
                 
                  <div class="ms-3">
                    <h2 class="text-white mb-0" data-toggle="counter-up">
                      253
                    </h2>
                    <p class="text-white mb-0">Happy Clients</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="d-flex rounded p-3"
                  style={{ background: "rgba(256, 256, 256, 0.1)" }}
                >
                  <i class="fa fa-check fa-3x text-white"></i>
                  <div class="ms-3">
                    <h2 class="text-white mb-0" data-toggle="counter-up">
                      17
                    </h2>
                    <p class="text-white mb-0">Project Complete</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 align-self-end text-center text-md-end wow fadeIn"
            data-wow-delay="0.5s"
          >
            <img
              class="img-fluid"
              src="https://demo.htmlcodex.com/2696/artificial-intelligence-html-template/img/feature.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
