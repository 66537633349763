import React from "react";

const FAQs = () => {
  return (
    <div class="container-fluid py-5">
      <div class="container py-5">
        <div
          class="mx-auto text-center wow fadeIn"
          data-wow-delay="0.1s"
          style={{maxWidth: "500px"}}
        >
          <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">
            Popular FAQs
          </div>
          <h1 class="mb-4">Frequently Asked Questions</h1>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="accordion" id="accordionFAQ1">
              <div class="accordion-item wow fadeIn" data-wow-delay="0.1s">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    How to build a website?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              <div class="accordion-item wow fadeIn" data-wow-delay="0.2s">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    How long will it take to get a new website?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              <div class="accordion-item wow fadeIn" data-wow-delay="0.3s">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Do you only create HTML websites?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              <div class="accordion-item wow fadeIn" data-wow-delay="0.4s">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Will my website be mobile-friendly?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionFAQ1"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="accordion" id="accordionFAQ2">
              <div class="accordion-item wow fadeIn" data-wow-delay="0.5s">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Will you maintain my site for me?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              <div class="accordion-item wow fadeIn" data-wow-delay="0.6s">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    I’m on a strict budget. Do you have any low cost options?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              <div class="accordion-item wow fadeIn" data-wow-delay="0.7s">
                <h2 class="accordion-header" id="headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Will you maintain my site for me?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
              <div class="accordion-item wow fadeIn" data-wow-delay="0.8s">
                <h2 class="accordion-header" id="headingEight">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    I’m on a strict budget. Do you have any low cost options?
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionFAQ2"
                >
                  <div class="accordion-body">
                    Dolor nonumy tempor elitr et rebum ipsum sit duo duo. Diam
                    sed sed magna et magna diam aliquyam amet dolore ipsum erat
                    duo. Sit rebum magna duo labore no diam.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
